import React from 'react';
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";

const Redirect = () => {
    return (
        <div style={{ textAlign: 'center'}}>
            <Text type='d100'>Please use new URL</Text>
            <Link href='https://admins-portal.lighthouse.amazon.dev/'>New URL</Link>
        </div>
    )
}

export default Redirect;