import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@amzn/meridian/card';
import Divider from '@amzn/meridian/divider';
import Column from '@amzn/meridian/column';
import Image from '@amzn/meridian/image';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Expander from '@amzn/meridian/expander';
import Button from '@amzn/meridian/button';
import Box from '@amzn/meridian/box';
import Loader from '@amzn/meridian/loader';
import WarehouseGraphic from '../images/WarehouseGraphic.png';
import ShiftGraphic from '../images/ShiftGraphic.png';
import DepartmentGraphic from '../images/DepartmentGraphic.png';
import ProcessesGraphic from '../images/ProcessesGraphic.png';
import Tables from '../components/features/tables';
import TablePopover from '../components/features/tablePopover';
import { adminsEntryPoint } from '../api';
import { GetAllFCDataRequest, GetAllFCDataResponse, EMPTY_GET_ALL_FC_DATA_RESPONSE } from '../api/models/fcData';
import { Department, EMPTY_DEPARTMENT } from '../api/models/department';
import { Warehouse, EMPTY_WAREHOUSE } from '../api/models/warehouse';
import { Shift, EMPTY_SHIFT } from '../api/models/shift';
import { Process, EMPTY_PROCESS } from '../api/models/process';
import { AuthContext } from '../auth/authContext';
import {
    WAREHOUSE_HEADER_ROW,
    SHIFT_HEADER_ROW,
    DEPARTMENT_HEADER_ROW,
    PROCESSES_HEADER_ROW
} from '../common/constants'
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import WarehouseModal from '../components/features/modals/warehouseModal'
import ShiftModal from '../components/features/modals/shiftModal';
import DepartmentModal from '../components/features/modals/departmentModal';
import ProcessModal from '../components/features/modals/processModal';

const FcData = () => {
    const [fcData, setFcData] = useState<GetAllFCDataResponse>(EMPTY_GET_ALL_FC_DATA_RESPONSE)
    // Expander booleans
    const [warehouseOpen, setWarehouseOpen] = useState<boolean>(false)
    const [shiftOpen, setShiftOpen] = useState<boolean>(false)
    const [departmentOpen, setDepartmentOpen] = useState<boolean>(false)
    const [processesOpen, setProcessesOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Modal booleans
    const [warehouseModalOpen, setWarehouseModalOpen] = useState<boolean>(false)
    const [shiftModalOpen, setShiftModalOpen] = useState<boolean>(false)
    const [shiftConfirmOpen, setShiftConfirmOpen] = useState<boolean>(false)
    const [deptModalOpen, setDeptModalOpen] = useState<boolean>(false)
    const [deptConfirmOpen, setDeptConfirmOpen] = useState<boolean>(false)
    const [processModalOpen, setProcessModalOpen] = useState<boolean>(false)
    const [processConfirmOpen, setProcessConfirmOpen] = useState<boolean>(false)
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);

    // Table data
    const [warehousesToEdit, setWarehousesToEdit] = useState<Warehouse>(EMPTY_WAREHOUSE);
    const [shiftToEdit, setShiftToEdit] = useState<Shift>(EMPTY_SHIFT);
    const [deptToEdit, setDeptToEdit] = useState<Department>(EMPTY_DEPARTMENT);
    const [processToEdit, setProcessToEdit] = useState<Process>(EMPTY_PROCESS);

    const { location } = useContext(AuthContext)
    const tableRef = useRef()
    const navigate = useNavigate()

    const fetchData = useCallback(() => {
        setIsLoading(true)
        adminsEntryPoint<GetAllFCDataRequest, GetAllFCDataResponse>({
            api_type: 'GET_ALL_FC_DATA',
            api_input: {
                warehouse_id: location
            }
        }).then((response: GetAllFCDataResponse) => {
            setFcData(response)
            setIsLoading(false)
        }).catch(error => {
            console.error(error)
        })
    }, [location])

    useEffect(() => {
        if (window.location.hostname === 'admins-portal.reset.amazon.dev') {
            navigate('/redirect')
        }
        fetchData()
    }, [fetchData, navigate])

    const warehouseEditRow = (rowData: Warehouse) => {
        setIsCreateMode(false)
        setWarehouseModalOpen(true)
        setWarehousesToEdit(rowData)
    }

    const shiftEditRow = (rowData: Shift) => {
        setShiftConfirmOpen(false)
        setIsCreateMode(false)
        setShiftModalOpen(true)
        setShiftToEdit(rowData)
    }
    
    const shiftDeleteRow = (rowData: Shift) => {
        setShiftConfirmOpen(true)
        setShiftModalOpen(true)
        setShiftToEdit(rowData)
    }

    const deptEditRow = (rowData: Department) => {
        setDeptConfirmOpen(false)
        setIsCreateMode(false)
        setDeptModalOpen(true)
        setDeptToEdit(rowData)
    }

    const deptDeleteRow = (rowData: Department) => {
        setDeptConfirmOpen(true)
        setDeptModalOpen(true)
        setDeptToEdit(rowData)
    }

    const processEditRow = (rowData: Process) => {
        setProcessConfirmOpen(false)
        setIsCreateMode(false)
        setProcessModalOpen(true)
        setProcessToEdit(rowData)
    }

    const processDeleteRow = (rowData: Process) => {
        setProcessConfirmOpen(true)
        setProcessModalOpen(true)
        setProcessToEdit(rowData)
    }

    const isWarehouseEmpty = fcData.warehouses.length === 0 || !fcData.warehouses[0].warehouse_id;
    const WAREHOUSE_DATA = fcData.warehouses.map((warehouse) => [
        warehouse.warehouse_id || '',
        warehouse.timezone || '',
        (warehouse.utc_offset || '').toString(),
        warehouse.linked_warehouses || '-',
        warehouse.country || '',
        warehouse.operations_manager || '',
        <TablePopover 
            ref={tableRef} 
            onEdit={() => warehouseEditRow(warehouse)}
            onDelete={() => {}}
            isDisabled={true}
        />
    ])

    const SHIFT_DATA = fcData.shifts.map((shifts) => [
        shifts.warehouse_id || '',
        shifts.shift_name || '',
        <span style={{ color: shifts.active ? 'green' : 'red' }}>
            {shifts.active ? 'true' : 'false'}
        </span>,
        <TablePopover 
            ref={tableRef} 
            onEdit={() => shiftEditRow(shifts)}
            onDelete={() => shiftDeleteRow(shifts)}
        />
    
    ])

    const DEPARTMENT_DATA = fcData.departments.map((department) => [
        department.warehouse_id || '',
        department.department_name || '',
        department.short_name || '-',
        department.flow_path || '',
        <span style={{ color: department.active ? 'green' : 'red' }}>
            {department.active ? 'true' : 'false'}
        </span>,
        <TablePopover 
            ref={tableRef} 
            onEdit={() => deptEditRow(department)}
            onDelete={() => {deptDeleteRow(department)}}
        />
    ])

    const PROCESSES_DATA = fcData.processes.map((processes) => [
        processes.warehouse_id || '',
        processes.process_name || '',
        processes.short_name || '-',
        processes.department || '',
        processes.function_type || '',
        <span style={{ color: processes.active ? 'green' : 'red' }}>
            {processes.active ? 'true' : 'false'}
        </span>,
        <TablePopover 
            ref={tableRef} 
            onEdit={() => processEditRow(processes)}
            onDelete={() => {processDeleteRow(processes)}}
        />
    ])

    const openWarehouseCreateModal = () => {
        setWarehousesToEdit(EMPTY_WAREHOUSE)
        setIsCreateMode(true)
        setWarehouseModalOpen(true)
    }

    const openCreateShiftModal = () => {
        setShiftToEdit(EMPTY_SHIFT)
        setIsCreateMode(true)
        setShiftModalOpen(true)
    }

    const openCreateDeptModal = () => {
        setDeptToEdit(EMPTY_DEPARTMENT)
        setIsCreateMode(true)
        setDeptModalOpen(true)
    }

    const openCreateProcessModal = () => {
        setProcessToEdit(EMPTY_PROCESS)
        setIsCreateMode(true)
        setProcessModalOpen(true)
    }

    

    return (
        <Column spacingInset='400' alignmentHorizontal='center'>
            <Text type='d100' alignment='center'>
                {location} Data Portal
            </Text>
            {isLoading ? (
                <div style={{ textAlign: 'center' }}>
                    <Loader type='circular'/>
                </div>
            ) : isWarehouseEmpty ? (
                <Card>
                    <Image 
                        src={WarehouseGraphic}
                        size='100%'
                        viewportAspectRatio={1}
                        viewportShape='circle'
                    />
                    <Column spacingInset='300'>
                        <Text type='b500'>
                            No Warehouses Found
                        </Text>
                        <Divider/>
                        <Button onClick={openWarehouseCreateModal}>
                            OnBoard
                        </Button>
                    </Column>
                </Card>
            ) : (
                <>
                    <Card spacingInset='none' width="90%">
                        <Row width='100%' widths={["grid-1", "grid-10"]}>
                            <Box spacingInset='300 none 300 200'>
                                <Image 
                                    src={WarehouseGraphic}
                                    size='100%'
                                    viewportAspectRatio={1}
                                    viewportShape='circle'
                                />
                            </Box>
                            <Expander
                                open={warehouseOpen}
                                onChange={setWarehouseOpen}
                                title="Warehouses"
                                type='inline'
                            >
                                <Column spacingInset='400'>
                                    <Tables 
                                        headers={WAREHOUSE_HEADER_ROW}
                                        data={WAREHOUSE_DATA}
                                        isLoading={isLoading}
                                        column={"Warehouse"}
                                        direction={"ascending"}
                                        isSearchable={false}
                                    />
                                </Column>
                            </Expander>
                        </Row>
                    </Card>
                    <Card spacingInset='none' width="90%">
                        <Row width='100%' widths={["grid-1", "grid-10"]}>
                            <Box spacingInset='400 none none 200'>
                                <Image 
                                    src={ShiftGraphic}
                                    size='100%'
                                    viewportAspectRatio={1}
                                    viewportShape='circle'
                                />
                                <Tooltip position='end' title="Add Shift">
                                    <Row alignmentHorizontal='center'>
                                        <Button 
                                            onClick={openCreateShiftModal} 
                                            size='small' 
                                            type='link'
                                        >
                                            <Icon tokens={plusTokens}>Add</Icon>
                                        </Button>
                                    </Row>
                                </Tooltip>
                            </Box>
                            <Expander
                                open={shiftOpen}
                                onChange={setShiftOpen}
                                title="Shifts"
                                type='inline'
                            >
                                <Column spacingInset='400'>
                                    <Tables 
                                        headers={SHIFT_HEADER_ROW}
                                        data={SHIFT_DATA}
                                        isLoading={isLoading}
                                        column={"Shift"}
                                        direction={"ascending"}

                                    />
                                </Column>
                            </Expander>
                        </Row>
                    </Card>
                    <Card spacingInset='none' width="90%">
                        <Row width='100%' widths={["grid-1", "grid-10"]}>
                            <Box spacingInset='400 none none 200'>
                                <Image 
                                    src={DepartmentGraphic}
                                    size='100%'
                                    viewportAspectRatio={1}
                                    viewportShape='circle'
                                />
                                <Tooltip position='end' title="Add Department">
                                    <Row alignmentHorizontal='center'>
                                        <Button onClick={openCreateDeptModal} size='small' type='link'>
                                            <Icon tokens={plusTokens}>Add</Icon>
                                        </Button>
                                    </Row>
                                </Tooltip>
                            </Box>
                            <Expander
                                open={departmentOpen}
                                onChange={setDepartmentOpen}
                                title="Departments"
                                type='inline'
                            >
                                <Column spacingInset='400'>
                                    <Tables 
                                        headers={DEPARTMENT_HEADER_ROW}
                                        data={DEPARTMENT_DATA}
                                        isLoading={isLoading}
                                        column={"Department Name"}
                                        direction={"ascending"}
                                    />
                                </Column>
                            </Expander>
                        </Row>
                    </Card>
                    <Card spacingInset='none' width="90%">
                        <Row width='100%' widths={["grid-1", "grid-10"]}>
                            <Box spacingInset='400 none none 200'>
                                <Image 
                                    src={ProcessesGraphic}
                                    size='100%'
                                    viewportAspectRatio={1}
                                    viewportShape='circle'
                                />
                                <Tooltip position='end' title="Add Process">
                                    <Row alignmentHorizontal='center'>
                                        <Button onClick={openCreateProcessModal} size='small' type='link'>
                                            <Icon tokens={plusTokens}>Add</Icon>
                                        </Button>
                                    </Row>
                                </Tooltip>
                            </Box>
                            <Expander
                                open={processesOpen}
                                onChange={setProcessesOpen}
                                title="Processes"
                                type='inline'
                            >
                                <Column spacingInset='400'>
                                    <Tables 
                                        headers={PROCESSES_HEADER_ROW}
                                        data={PROCESSES_DATA}
                                        isLoading={isLoading}
                                        column={"Process Name"}
                                        direction={"ascending"}
                                    />
                                </Column>
                            </Expander>
                        </Row>
                    </Card>
                </>
                )
            }
            <WarehouseModal 
                title={isCreateMode ? 'Create Warehouse' : 'Edit Warehouse'}
                open={warehouseModalOpen}
                onClose={() => setWarehouseModalOpen(false)}
                initialData={warehousesToEdit}
                onSave={() => {
                    fetchData()
                    setWarehouseModalOpen(false)
                }}
                isCreateMode={isCreateMode}
            />
            <ShiftModal
                title={isCreateMode ? 'Create Shift' : 'Edit Shift'}
                open={shiftModalOpen}
                onClose={() => setShiftModalOpen(false)}
                initialData={shiftToEdit}
                onSave={() => {
                    fetchData()
                    setShiftModalOpen(false)
                }}
                isCreateMode={isCreateMode}
                isDeleteMode={shiftConfirmOpen}
            />
            <DepartmentModal 
                title={isCreateMode ? 'Create Department' : 'Edit Department'}
                open={deptModalOpen}
                onClose={() => setDeptModalOpen(false)}
                initialData={deptToEdit}
                onSave={() => {
                    fetchData()
                    setDeptModalOpen(false)
                }}
                isCreateMode={isCreateMode}
                isDeleteMode={deptConfirmOpen}
            />
            <ProcessModal 
                title={isCreateMode ? 'Create Process' : 'Edit Process'}
                open={processModalOpen}
                onClose={() => setProcessModalOpen(false)}
                initialData={processToEdit}
                onSave={() => {
                    fetchData()
                    setProcessModalOpen(false)
                }}
                isCreateMode={isCreateMode}
                isDeleteMode={processConfirmOpen}
            />
            
        </Column>
    )

}

export default FcData;